import logo from './Logo.png';
import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate, useResolvedPath } from 'react-router-dom'
import './App.css';
import firebase from 'firebase/compat/app'
import AuthGoogle from './components/AuthGoogle';
import Authenticated from './components/Authenticated';
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getDatabase, ref, child, get } from "firebase/database";
import { auth } from 'firebaseui';

function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyDIYCAoNERYeckpZ9eHEJrPuPezvhXY2Rc",
    authDomain: "weaponstory.firebaseapp.com",
    databaseURL: "https://weaponstory-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "weaponstory",
    storageBucket: "weaponstory.appspot.com",
    messagingSenderId: "835269247478",
    appId: "1:835269247478:web:56cfb4870fbd2a3498c7d7",
    measurementId: "G-E3HCZE2FD5"
  };

  const totalServer = 2
  const serverNames = ["Test Server", "Server 1"]
  const [user, setUser] = useState(null)
  const [accounts, setAccounts] = useState(null)
  const [characters, setCharacters] = useState(null)
  const [ready, setReady] = useState(false)
  const [alreadyDoneProcess, setAlreadyDoneProcess] = useState("")
  const [loadlogin, setLoadlogin] = useState(false)

  // Initialize Firebase
  const fb = firebase.initializeApp(firebaseConfig);
  const database = getDatabase(fb);

  const doneProcess = (type) => {
    setAlreadyDoneProcess(type)
  }

  firebase.auth().onAuthStateChanged(user => {
    if (user) {
      setUser({ email: user.email, uid: user.uid })
    }
    else {
      setUser(null);
    }
  });

  useEffect(() => {
    if (user != null) {
      if (!loadlogin) {
        setLoadlogin(true);
        loadAccounts(user.uid, 0, Array());
      }
    }
    else {
      if (loadlogin) {
        setLoadlogin(false);
        setAccounts(null)
        setCharacters(null)
        setReady(false)
      }
    }
  }, [user])

  const loadAccounts = (userid, serverno, accDats) => {
    var formData = new FormData();
    var dCommand = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];
    var sCommand = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];
    var rVariable = ["adspoint", "wspoint", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];
    var lCommand = "SELECT * FROM accounts WHERE userid = '" + userid + "'";
    for (var i = 0; i < 20; i++) {
      formData.append('sqldeletecommand' + i, dCommand[i]);
      formData.append('sqlsavecommand' + i, sCommand[i]);
      formData.append('rt' + i, rVariable[i]);
    }
    formData.append('sqlloadcommand', lCommand);
    fetch('https://www.weapon-story.com/weaponstory/' + serverno + '_SQL_Controller.php', {
      method: 'POST',
      mode: 'cors',
      body: formData
    })
      .then((res) => {
        return res.text();
      }).then((data) => {
        //console.log(data);
        var eachData = data.split("_____");
        if (eachData != "") {
          accDats.push({ serverno: serverno, servername: serverNames[serverno], adspoint: eachData[0], wspoint: eachData[1] });
        }
        if (serverno < totalServer - 1) loadAccounts(userid, serverno + 1, accDats);
        else {
          if (accDats.length > 0) {
            setAccounts(accDats);
            loadCharacters(userid, 0, Array());
          }
          else {
            setAccounts(null);
            setReady(true);
          }
        }
      }).catch((error) => {
        //console.log('mute false error');
      })
  }

  const loadCharacters = (userid, serverno, allDatas) => {
    var formData = new FormData();
    var dCommand = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];
    var sCommand = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];
    var rVariable = ["mainstat", "mainstring", "lastlogin", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];
    var lCommand = "SELECT * FROM characters WHERE userid = '" + userid + "'";
    for (var i = 0; i < 20; i++) {
      formData.append('sqldeletecommand' + i, dCommand[i]);
      formData.append('sqlsavecommand' + i, sCommand[i]);
      formData.append('rt' + i, rVariable[i]);
    }
    formData.append('sqlloadcommand', lCommand);
    fetch('https://www.weapon-story.com/weaponstory/' + serverno + '_SQL_Controller.php', {
      method: 'POST',
      mode: 'cors',
      body: formData
    })
      .then((res) => {
        return res.text();
      }).then((data) => {
        //console.log(data);
        if (data != "") {
          data.split(";;;;;").forEach(rowData => {
            var plevel = rowData.split("_____")[0].split(",")[0];
            var level = rowData.split("_____")[0].split(",")[1];
            var charname = rowData.split("_____")[1].split("|")[0];
            var lastlogin = rowData.split("_____")[2];
            var dat = { serverno: serverno, servername: serverNames[serverno], plevel: plevel, level: level, charname: charname, lastlogin: lastlogin };
            allDatas.push(dat);
          });
        }
        if (serverno < totalServer - 1) loadCharacters(userid, serverno + 1, allDatas);
        else loadUserDatas(allDatas);
      }).catch((error) => {
        //console.log('mute false error');
      });
  }
  const loadUserDatas = (rawDatas) => {
    var allDatas = Array();
    if (rawDatas.length == 0) {
      setCharacters(null);
      setReady(true);
    }
    rawDatas.forEach(e => {
      get(child(ref(database), `${e.serverno}/userdatas/${e.charname}`)).then((snapshot) => {
        if (snapshot.exists()) {
          var timekey = snapshot.child("timekey").val();
          var status = timekey == 0 || timekey <= e.lastlogin ? "Offline" : "Online";
          var dat = { serverno: e.serverno, servername: e.servername, plevel: e.plevel, level: e.level, charname: e.charname, lastlogin: e.lastlogin, status: status };
          allDatas.push(dat);
          if (allDatas.length == rawDatas.length) {
            setCharacters(allDatas.length > 0 ? allDatas : null);
            setReady(true);
          }
        } else {
          //console.log("No data available");
        }
      })
        .catch((error) => {
          console.error(error);
        });
    });
  }
  return (
    <div className="App">
      <div className="title-header">
        <img src={logo} className='logo' alt="logo" />
        WeaponStory
      </div>
      {user != null ? (
        <div>
          <Authenticated totalServer={totalServer} serverNames={serverNames} user={user} ready={ready} doneProcess={doneProcess} accounts={accounts} characters={characters} />
        </div>
      ) : (
        <div>
          <AuthGoogle auth={firebase.auth()} />
          {alreadyDoneProcess === "ChangePassword" ? <div className="success-text">Password is changed, sign-in again</div> : ""}
          {alreadyDoneProcess === "Delete" ? <div className="alert-text">Account is deleted</div> : ""}
        </div>
      )}
    </div>
  );
}

export default App;
