import React, {useEffect} from "react";
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import firebase from 'firebase/compat/app'
import '../App.css';

const AuthGoogle = (props) => {
    useEffect(()=>{
        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(props.auth)
        ui.start('.firebase-auth-container', {
            signInOptions: [
                {
                    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    requireDisplayName: false,
                    disableSignUp: { status: true, adminEmail: "weaponstory@gmail.com", helpLink: "" }
                }
            ],
            signInSuccessUrl: '',//'/authenticated',
            privacyPolicyUrl: 'https://sites.google.com/view/weaponstory-privacy-policy/%E0%B8%AB%E0%B8%99%E0%B8%B2%E0%B9%81%E0%B8%A3%E0%B8%81'
        });
    }, [props.auth])
    return (
        <div>
            <div className={'firebase-auth-container'}></div>
        </div>
    )
}

export default AuthGoogle