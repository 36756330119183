import React, { useState } from "react";
import firebase from 'firebase/compat/app'
import '../App.css';
import { v4 as uuidv4 } from 'uuid';
import { deleteUser, updatePassword } from "firebase/auth";
import { getDatabase, remove, ref } from "firebase/database"

const Authenticated = (props) => {
    const [changePassword, setChangePassword] = useState(false)
    const [passwordNotMatched, setPasswordNotMatched] = useState(false)
    const [deleteAccount, setDeleteAccount] = useState(false)
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const onChangeNewPassword = (event) => {
        setNewPassword(event.target.value)
    }
    const onChangeConfirmPassword = (event) => {
        setConfirmPassword(event.target.value)
    }
    const showChangePassword = () => {
        setNewPassword("")
        setConfirmPassword("")
        setPasswordNotMatched(false)
        setChangePassword(true)
    }
    const applyUpdatePassword = () => {
        if (newPassword !== confirmPassword) {
            setPasswordNotMatched(true)
            return;
        }
        firebase.auth().currentUser.updatePassword(newPassword).then(() => {
            firebase.auth().signOut()
            props.doneProcess("ChangePassword")
            setPasswordNotMatched(false)
            setChangePassword(false)
        })
    }
    const applyDeleteAccount = () => {
        for (var i = 0; i < props.totalServer; i++) {
            var formData = new FormData();
            var dCommand = ["DELETE FROM accounts WHERE userid = '" + props.user.uid + "'", // 0
            "DELETE FROM characters WHERE userid = '" + props.user.uid + "'", // 1
            "DELETE FROM storages WHERE userid = '" + props.user.uid + "'", // 2
            "DELETE FROM items WHERE userid = '" + props.user.uid + "'", // 3
            "DELETE FROM limitplayers WHERE userid = '" + props.user.uid + "'", // 4
            "DELETE FROM skills WHERE userid = '" + props.user.uid + "'", // 5
            "DELETE FROM buffs WHERE userid = '" + props.user.uid + "'", // 6
            "DELETE FROM diseases WHERE userid = '" + props.user.uid + "'", // 7
            "DELETE FROM npccooldowns WHERE userid = '" + props.user.uid + "'", // 8
                "", "", "", "", "", "", "", "", "", "", ""];
            var sCommand = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];
            var rVariable = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];
            var lCommand = "";

            if (props.characters != null) {
                var allCharNames = "";
                props.characters.forEach(e => {
                    if (e != null) {
                        if (e.servername == props.serverNames[i]) {
                            allCharNames = (allCharNames == "" ? "" : ", ") + "'" + e.charname + "'";
                        }
                    }
                });
                dCommand[9] = "DELETE FROM mails WHERE toname IN (" + allCharNames + ")";
                dCommand[10] = "DELETE FROM markets WHERE seller IN (" + allCharNames + ")";
                dCommand[11] = "DELETE FROM friend WHERE charname IN (" + allCharNames + ") OR friendname IN (" + allCharNames + ")";
                dCommand[12] = "DELETE FROM crafts WHERE ownername IN (" + allCharNames + ")";
                dCommand[13] = "DELETE FROM blueprints WHERE blueprinttype = 'CHAR' AND blueprintname IN (" + allCharNames + ")";
                dCommand[14] = "DELETE FROM coupons WHERE redeemuserid = '" + props.user.uid + "' OR redeemcharname IN (" + allCharNames + ")";
                dCommand[15] = "DELETE FROM fragments WHERE charname IN (" + allCharNames + ")";
                dCommand[16] = "DELETE FROM repairs WHERE charname IN (" + allCharNames + ")";
                for (var j = 0; j < 20; j++) {
                    formData.append('sqldeletecommand' + j, dCommand[j]);
                    formData.append('sqlsavecommand' + j, sCommand[j]);
                    formData.append('rt' + j, rVariable[j]);
                }
                formData.append('sqlloadcommand', lCommand);
                fetch('https://www.weapon-story.com/weaponstory/' + i + '_SQL_Controller.php', {
                    method: 'POST',
                    mode: 'cors',
                    body: formData
                })
                    .then((res) => {
                        //return res.text();
                    }).then((data) => {
                        //console.log(data);
                    }).catch((error) => {
                        //console.log('mute false error');
                    });
            }
        }
        props.characters.forEach(e => {
            if (e != null) {
                remove(ref(getDatabase(), e.serverno + "/userdatas/" + e.charname))
            }
        });
        deleteUser(firebase.auth().currentUser)
        .then(()=>{
            props.doneProcess("Delete")
            setDeleteAccount(false)
        })
    }

    if (props.ready) {
        return (
            <div>
                <div className="character-container">
                    <table className="character-table">
                        <thead>
                            <tr className="character-intable" key={uuidv4()}>
                                <th className="character-intable">Email : {props.user.email}</th>
                                {/*
                                    <th className="character-intable">UID</th>
                                */}
                                <th className="character-intable">Ads Point</th>
                                <th className="character-intable">WS Point</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.accounts != null ?
                                    (props.accounts.map(e => {
                                        if (e != null) {
                                            return (
                                                <tr className="character-intable" key={uuidv4()}>
                                                    <td className="character-intable">{e.servername}</td>
                                                    <td className="character-intable">{e.adspoint}</td>
                                                    <td className="character-intable">{e.wspoint}</td>
                                                </tr>
                                            )
                                        }
                                    }))
                                    : (
                                        <tr className="character-intable" key={uuidv4()}>
                                            <td className="character-intable">No data</td>
                                            <td className="character-intable">-</td>
                                            <td className="character-intable">-</td>
                                        </tr>
                                    )
                            }
                        </tbody>
                    </table>
                </div>

                <div className="character-container">
                    <table className="character-table">
                        <thead>
                            <tr className="character-intable" key={uuidv4()}>
                                <th className="character-intable">Server</th>
                                <th className="character-intable">Character Name</th>
                                <th className="character-intable">Level</th>
                                <th className="character-intable">Last Login (Status)</th>
                                <th className="character-intable">Remark</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.characters != null ?
                                    (props.characters.map(e => {
                                        if (e != null) {
                                            return (
                                                <tr className="character-intable" key={uuidv4()}>
                                                    <td className="character-intable">{e.servername}</td>
                                                    <td className="character-intable">{e.charname}</td>
                                                    <td className="character-intable">{e.level}</td>
                                                    <td className="character-intable">{new Date(e.lastlogin * 1000).toUTCString()} ({e.status})</td>
                                                    <td className="character-intable">{e.plevel >= 5 ? " Developer" : ""}</td>
                                                </tr>
                                            )
                                        }
                                    })) : (
                                        <tr className="character-intable" key={uuidv4()}>
                                            <td className="character-intable">No data</td>
                                            <td className="character-intable">-</td>
                                            <td className="character-intable">-</td>
                                            <td className="character-intable">-</td>
                                        </tr>
                                    )
                            }
                        </tbody>
                    </table>
                </div>
                {!changePassword && !deleteAccount ? (
                    <div className="button-container">
                        <button className="button-normal" onClick={() => (showChangePassword())}>Change Password</button>
                        <button className="button-normal" onClick={() => firebase.auth().signOut()}>Sign Out</button>
                        <button className="button-delete" onClick={() => setDeleteAccount(true)}>Delete Account</button>
                    </div>
                ) : ""}
                {changePassword ? (
                    <div className="form-container">
                        <form className="form-changepassword">
                            <label>New Password</label>
                            <br></br>
                            <input type="password" value={newPassword} onChange={onChangeNewPassword} />
                            <br></br>
                            <label>Confirm New Password</label>
                            <br></br>
                            <input type="password" value={confirmPassword} onChange={onChangeConfirmPassword} />
                        </form>
                        <div className="button-container">
                            <button className="button-normal" onClick={() => applyUpdatePassword()}>Change Password</button>
                            <button className="button-normal" onClick={() => setChangePassword(false)}>Cancel</button>
                            {passwordNotMatched ? <div className="alert-text">Password not matched!</div> : ""}
                        </div>
                    </div>
                ) : ""}
                {deleteAccount ? (
                    <div className="form-container">
                        <div className="button-container">
                            <div className="alert-text">This function cannot be undo (All character data will removed)</div>
                            <button className="button-delete" onClick={() => applyDeleteAccount()}>Delete Account</button>
                            <button className="button-normal" onClick={() => setDeleteAccount(false)}>Cancel</button>
                        </div>
                    </div>
                ) : ""}
            </div>
        )
    }
}

export default Authenticated